import React from "react"
// import EventEmitter from "EventEmitter";
// import EventManager from "../components/eventManager";
// import ThreeContainer from './../components/threeContainer';
// import SCENE_CONSTANTS from './../constants/scenes';

// let EventBus = new EventEmitter();

export default () => (
    <div>
        Writing
    </div>
)